import React, { Component } from 'react';


class PrivacyPolicyDetailsComponent extends Component {
	render() {
		return (
			<div className="content-box" style={{"line-height":"22px"}}>
				<div className="page-heading">Privacy Policy</div>

				<div style={{"textAlign":"justify"}}>
					Aurvan Technologies recognizes that your privacy is very important, and we take it seriously.
					This Privacy Policy governs the manner in which Aurvan Technologies collects, 
					uses, maintains and discloses information collected from user(s) of products 
					and services provided by Aurvan Technologies.
					This privacy policy applies to all the products and services offered by Aurvan Technologies.
				</div>

				<div style={{"height":"20px"}}>&nbsp;</div>



				<div className="section-heading">Data Collection</div>

				<div style={{"height":"5px"}}>&nbsp;</div>

				<div style={{"textAlign":"justify"}}>
					While providing products and services we collect various types of data from user(s).
					This data collection happens during, but not limited to, the following,
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					As a part of registration process to create an account for using our products 
					and services, you will be asked to provide your Personal Data.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					We may collect your details when you login to your account while using any
					of our product(s) or service(s).
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					When you explicitly provide, upload, share Contacts Data using any of our products and services.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					When you communicate with us via various mechanisms, not limited to email,
					social networks, phone, contact provision within our products and services, etc.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					When you continue using our products and services, data is continuously monitored
					for changes and the changes are collected.
				</div>


				<div style={{"height":"10px"}}>&nbsp;</div>


				<div style={{"textAlign":"justify"}}>
					We collect information directly from users, from third parties and automatically
					through the Aurvan Technologies Platform. This data collection includes but not limited to the following,
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					Personal identification information such as name, email address, mailing address,
					phone number.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					Non-personal identification information about users whenever they interact
					with our products and services. Non-personal identification information may include
					but not limited to the system details like operating system name, browser name, 
					the type of computer, technical information about the means of connection to
					our products and services and details about user interation with our products and services.
				</div>



				<div style={{"height":"20px"}}>&nbsp;</div>

				<div className="section-heading">Data Usage</div>
				<div style={{"textAlign":"justify"}}>
					Aurvan Technologies use the information collected for, but not limited to, the following purposes,
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					To personalize user experience: We may use the information collected to
					understand how users use the products, services and resources provided by us.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					To improve our products and services: We may use the collected information to decide
					new features, plan new updates, introduce new products and services.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					To run a promotion, contest, survey or other features: The collected information
					may be used to notify users about the topics we think will be of interest to them.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					To improve customer service: The information collected helps us respond to customer service requests and their support needs more efficiently.
				</div>



				<div style={{"height":"20px"}}>&nbsp;</div>

				<div className="section-heading">Data Disclosure / Sharing</div>
				<div style={{"textAlign":"justify"}}>
					We do not share personal information with companies, organisations, and individuals
					outside of Aurvan Technologies except following circumstances,
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					Disclosure of the information is reasonably necessary to comply with applicable law,
					regulations, legal process, or enforceable governmental request.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					User has given concent to share and / or disclose the provided information.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					We may use third party service providers to help us operate our business which include
					but not limited to sending out newsletters or surveys, provide analytical details,
					serve advertisements. We may share your information with these third parties for
					those limited purposes.
				</div>



				<div style={{"height":"20px"}}>&nbsp;</div>

				<div className="section-heading">Data Security / Protection</div>
				<div style={{"textAlign":"justify"}}>
					We work hard to protect the information that we collected from unauthorized
					access, alteration, disclosure, or destruction. However, "perfect security"
					does not exist on the internet. You, therefore, agree that security breaches
					beyond the control of our standard security procedures are at your sole risk
					and discretion. The measures that we take to protect the information
					may include but not limited to the following,
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					SSL secured communication channel, encrypted and protected data with digital signatures
					will be used wherever we feel necessary.
				</div>

				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					Authorisation and authentication processes are implemeted to make sure that the 
					information is well protected.
				</div>



				<div style={{"height":"20px"}}>&nbsp;</div>

				<div className="section-heading">Privacy Policy Changes</div>
				<div style={{"textAlign":"justify"}}>
					Aurvan Technologies have the discretion to update this privacy policy at any time 
					without any intimation. When we do, we will notify you via various contanct means 
					like, but not limited to, email, in-app notifications. However, you acknowledge
					and agree that it is your responsibility to review this privacy policy periodically 
					and become aware of modifications.
				</div>



				<div style={{"height":"20px"}}>&nbsp;</div>

				<div className="section-heading">Disclaimer</div>
				<div style={{"textAlign":"justify"}}>
					We cannot ensure that all of your information will never be disclosed in ways
					not otherwise described in this Privacy Policy. Therefore, although we are
					committed to protecting your privacy, we do not promise, and you should not
					expect, that your personal information will always remain private.
					As a user of the products, services and resources provided by Aurvan Technologies,
					you understand and agree that you assume all responsibility and risk for the usage.
				</div>


				<div style={{"height":"30px"}}>&nbsp;</div>
			</div>
		);
	}
}

export default PrivacyPolicyDetailsComponent;
