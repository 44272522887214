import React, { Component } from 'react';
import './at-quick-look.css';


class ATQuickLook extends Component {

	constructor(props) {
		super(props);

		this.quickLookOverlayContainer = React.createRef();

		this._shouldDisplay = false
		this._shouldDisplayLoadingIndicator = false
		
		this.quickLookContentWidth = 100;
		this.quickLookContentHeight = 100;
		this.quickLookContentTopMargin = 0;
	}
	
	componentDidMount() {
		window.addEventListener("resize", this.reloadAllView.bind(this));
		this.reloadAllView();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.reloadAllView.bind(this));
	}

	reloadAllView() {
		var anOverlayHeight = this.quickLookOverlayContainer.current.clientHeight <= 0 ? this.quickLookContentHeight : this.quickLookOverlayContainer.current.clientHeight;
		this.quickLookContentTopMargin = (anOverlayHeight - this.quickLookContentHeight) / 2;
		this.forceUpdate();
	}

	showLoadingIndicator() {
		this.quickLookContentImageSrc = null;
		this._shouldDisplay = true
		this._shouldDisplayLoadingIndicator = true
		this.quickLookContentWidth = 100;
		this.quickLookContentHeight = 100;
		this.reloadAllView();
	}

	showImage(pImage) {
		this.quickLookContentImageSrc = pImage.getAttribute("src");

		var anImageWidth = pImage.width <= 0 ? 100 : pImage.width;
		var anImageHeight = pImage.height <= 0 ? 100 : pImage.height;

		var anOverlayWidth = this.quickLookOverlayContainer.current.clientWidth;
		var anOverlayHeight = this.quickLookOverlayContainer.current.clientHeight;

		var aQuickLookContentWidth = 100;
		var aQuickLookContentHeight = 100;
		if (anOverlayHeight < anOverlayWidth) {
			aQuickLookContentHeight = anOverlayHeight * 0.8;
			aQuickLookContentWidth = (aQuickLookContentHeight * anImageWidth) / anImageHeight;
		} else {
			aQuickLookContentWidth = anOverlayWidth * 0.8;
			aQuickLookContentHeight = (aQuickLookContentWidth * anImageHeight) / anImageWidth;
		}

		this._shouldDisplay = true
		this._shouldDisplayLoadingIndicator = false
		this.quickLookContentWidth = aQuickLookContentWidth;
		this.quickLookContentHeight = aQuickLookContentHeight;
		this.reloadAllView();
	}

	showWithUrl(pUrl) {
		this.showLoadingIndicator();

		var aThis = this;
		var anImage = new Image();
		anImage.src = pUrl;
		anImage.addEventListener("load", function(pEvent) {
			aThis.showImage(this);
		}, false);
	}

	hide() {
		this._shouldDisplay = false
		this.reloadAllView();
	}

	didTapOverlay(pEvent) {
		if(pEvent.target === pEvent.currentTarget) {
			pEvent.preventDefault();
			pEvent.stopPropagation();
			this.hide();
		}
	}

	render() {
		return (
			<div className="at-quick-look-overlay" style={{"visibility":(this._shouldDisplay === true ? "visible" : "hidden")}} ref={this.quickLookOverlayContainer} onClick={this.didTapOverlay.bind(this)}>
				<div className="at-quick-look-loading-indicator" style={{"visibility":((this._shouldDisplayLoadingIndicator === true && this._shouldDisplay === true) ? "visible" : "hidden")}}></div>
				<img className="at-quick-look-content" style={{"width":this.quickLookContentWidth, "height":this.quickLookContentHeight, "marginTop":this.quickLookContentTopMargin}} src={this.quickLookContentImageSrc} alt="" />
			</div>
		);
	}
}

export {ATQuickLook}