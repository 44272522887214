import React, { Component } from 'react';

import {URL} from './configuration-manager';


export class NavigationBarComponent extends Component {
	
	didSelectHomeButton() {
		window.location = URL.homePage();
	}

	didSelectProductsButton() {
		window.location = URL.searchProduct();
	}

	didSelectSupportButton() {
		window.location = URL.supportDetails();
	}

	didSelectPortfolioButton() {
		window.location = URL.portfolioDetails();
	}

	didSelectDashboardButton() {
		window.location = URL.loginCustomerForm();
	}

	didSelectNavigationDropdown() {
		document.getElementById("NavigationDropdown").classList.toggle("navigation-dropdown-selected");
	}

	render() {
		return (
			<div className="navigation-bar">
				<div className="content-box">
					<div className="banner-bar">
						<div className="banner">Aurvan Technologies</div>
					</div>
					<div className="navigation-content-bar">
						&nbsp;<span className="navigation-link" onClick={this.didSelectHomeButton}>Home</span>
						&nbsp;<span className="navigation-link" onClick={this.didSelectProductsButton}>Products</span>
						&nbsp;<span className="navigation-link" onClick={this.didSelectSupportButton}>Support</span>
						&nbsp;<span className="navigation-link" onClick={this.didSelectPortfolioButton}>Portfolio</span>
						&nbsp;<span className="navigation-link" onClick={this.didSelectDashboardButton}>Dashboard</span>
						<div className="navigation-dropdown" id="NavigationDropdown" onClick={this.didSelectNavigationDropdown}>
							<span className="app-input-button app-input-button-square square-button-hamburger"></span>
							<div className="navigation-dropdown-content">
								<div className="navigation-dropdown-link" onClick={this.didSelectHomeButton}>Home</div>
								<div className="navigation-dropdown-link" onClick={this.didSelectProductsButton}>Products</div>
								<div className="navigation-dropdown-link" onClick={this.didSelectSupportButton}>Support</div>
								<div className="navigation-dropdown-link" onClick={this.didSelectPortfolioButton}>Portfolio</div>
								<div className="navigation-dropdown-link" onClick={this.didSelectDashboardButton}>Dashboard</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

 }

 export default NavigationBarComponent;