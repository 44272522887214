import React, { Component } from 'react';


class LoginCustomerComponent extends Component {

	didSelectLoginButton() {
		
	}

	render() {
		return (
			<div className="content-box">
				
				<div className="page-heading">Login</div>
				
				<div style={{"clear":"both"}}>
					<div className="login-customer-container">
						<div className="login-customer-input-container"><input type="text" className="app-input-textfield" placeholder="Email Address" /></div>
						<div className="login-customer-input-container"><input type="password" className="app-input-textfield" placeholder="Password" /></div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="login-customer-input-container"><input type="submit" className="app-input-button" value="Login" onClick={this.didSelectLoginButton} /></div>
					</div>
				</div>
				
				<div style={{"height":"20px"}}>&nbsp;</div>
				
			</div>
		);
	}
}

export default LoginCustomerComponent;
