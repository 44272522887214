import React, { Component } from 'react';

import {URL} from './configuration-manager';


class SearchProductComponent extends Component {

	didSelectDownloadSqlightLatestButton() {
		window.location = URL.downloadSqlightLatest();
	}

	didSelectSqlightMoreInfoButton() {
		window.location = URL.sqlightDetails();
	}


	didSelectDownloadSolitaireIosLatestButton() {
		window.location = URL.downloadSolitaireIosLatest();
	}

	didSelectDownloadSolitaireAndroidLatestButton() {
		window.location = URL.downloadSolitaireAndroidLatest();
	}

	didSelectSolitaireMoreInfoButton() {
		window.location = URL.solitaireDetails();
	}

	componentWillMount() {
		this.productSummaryImageSqlight = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-icon-128x128.png";
		this.productSummaryImageSolitaire = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-icon-128x128.png";
	}

	render() {
		return (
			<div className="content-box">

			<div style={{"height":"20px"}}>&nbsp;</div>

			<div className="product-summary-container">
				<div className="product-summary-image" style={{"backgroundImage": "url(" + this.productSummaryImageSqlight + ")"}}>&nbsp;</div>
				<div id="product-summary-links-vertical">
					<div className="product-summary-link-button" onClick={this.didSelectDownloadSqlightLatestButton}>Free Download</div>
					<div className="product-summary-link-button" style={{display: "inline-block"}} onClick={this.didSelectSqlightMoreInfoButton}>More Info</div>
				</div>
				<div className="product-summary-text">
					<div className="product-summary-title">SQLight</div>
					<div style={{"height":"10px"}}>&nbsp;</div>
					<div className="product-summary-description">
						SQLight is an SQLite database manager tool. With this tool, you can browse, edit,
						delete data from local SQLite file with easy to use UI and controls.
						If you want to perform SQL query operation on database, there is a query box.
						Type your query and click the button to execute and get results.
						You can also export data as a file and save it on your drive.
					</div>
				</div>
				<div id="product-summary-links-horizontal">
					<div className="product-summary-link-button" onClick={this.didSelectDownloadSqlightLatestButton}>Free Download</div>
					<div className="product-summary-link-button" style={{display: "inline-block"}} onClick={this.didSelectSqlightMoreInfoButton}>More Info</div>
				</div>
			</div>


			<div style={{"height":"15px"}}>&nbsp;</div>
			<div className="horizontal-line"></div>
			<div style={{"height":"15px"}}>&nbsp;</div>


			<div className="product-summary-container">
				<div className="product-summary-image" style={{"backgroundImage": "url(" + this.productSummaryImageSolitaire + ")"}}>&nbsp;</div>
				<div id="product-summary-links-vertical">
				<div className="product-summary-link-button" onClick={this.didSelectDownloadSolitaireIosLatestButton}>Free Download (iOS)</div>
					<div className="product-summary-link-button" onClick={this.didSelectDownloadSolitaireAndroidLatestButton}>Free Download (Android)</div>
					<div className="product-summary-link-button" style={{display: "inline-block"}} onClick={this.didSelectSolitaireMoreInfoButton}>More Info</div>
				</div>
				<div className="product-summary-text">
					<div className="product-summary-title">Solitaire</div>
					<div style={{"height":"10px"}}>&nbsp;</div>
					<div className="product-summary-description">
					Solitaire is an iPhone version of popular card game, Solitaire. It is played 
					according to the old Klondike Solitaire rules with a standard fifty two card 
					deck without Jokers. Goal is to move all the fifty two cards to four foundation 
					trays, one for each suit as club, diamond, heart and spade. Each of the four 
					foundations is built up with the same suit from Ace to King and the containers 
					can be built down by alternate colors.
					</div>
				</div>
				<div id="product-summary-links-horizontal">
					<div className="product-summary-link-button" onClick={this.didSelectDownloadSolitaireIosLatestButton}>Free Download (iOS)</div>
					<div className="product-summary-link-button" onClick={this.didSelectDownloadSolitaireAndroidLatestButton}>Free Download (Android)</div>
					<div className="product-summary-link-button" style={{display: "inline-block"}} onClick={this.didSelectSolitaireMoreInfoButton}>More Info</div>
				</div>
			</div>


			<div style={{"height":"20px"}}>&nbsp;</div>

			</div>
		);
	}
}

export default SearchProductComponent;
