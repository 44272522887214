import React, { Component } from 'react';
import './at-carousel.css';


class ATCarousel extends Component {

	constructor(props, context) {
		super(props, context)

		this.state = {
			leftArrowDisplay : "none"
			, rightArrowDisplay : "none"
			, currentIndex: 0
		}

		this.touchEventCoordinates = {
			startX: 0,
			startY: 0,
			endX: 0,
			endY: 0
		};
	}

	render() {
		var aCarouselBulletArray = [];
		for (var anIndex = 0; anIndex < this.props.children.length; anIndex++) {
			var aBulletClass = "at-carousel-bullet";
			if (anIndex === this.state.currentIndex) {
				aBulletClass += " at-carousel-bullet-active";
			}
			let aBulletIndex = anIndex; // aBulletIndex must be "let" otherwise it will hold shallow copy.
			aCarouselBulletArray.push(<span key={aBulletIndex} className={aBulletClass} onClick={()=>this.didSelectPageIndicatorBulletAtIndex(aBulletIndex)}></span>);
		}

		const aCarouselSlideArray = React.Children.map(this.props.children, (pChild, pIndex) => {
			return React.cloneElement(pChild, {
				transformPercent: (100 * -this.state.currentIndex)
			});
		});

		return (
			<div className="at-carousel" onMouseEnter={()=>this.onMouseEnterHandler()} onMouseLeave={()=>this.onMouseLeaveHandler()} onTouchStart={this.touchDidStart.bind(this)} onTouchMove={this.touchDidMove.bind(this)} onTouchEnd={this.touchDidEnd.bind(this)}>
				<div className="at-carousel-arrow-left" style={{"display":this.state.leftArrowDisplay}} onClick={()=>this.didSelectLeftArrow()}></div>
				{aCarouselSlideArray}
				<div className="at-carousel-arrow-right" style={{"display":this.state.rightArrowDisplay}} onClick={()=>this.didSelectRightArrow()}></div>
				<div className="at-carousel-bullet-container">{aCarouselBulletArray}</div>
			</div>
		)
	}
	
	componentWillMount() {
		this.activateSlideAtIndex(0)
	}
	
	activateSlideAtIndex(pIndex) {
		if (pIndex >= 0 && pIndex < this.props.children.length) {
			this.setState({
				currentIndex: pIndex
			})
		}
	}

	onMouseEnterHandler() {
		this.setState({
			leftArrowDisplay: "block"
			, rightArrowDisplay : "block"
		});
	}

	onMouseLeaveHandler() {
		this.setState({
			leftArrowDisplay: "none"
			, rightArrowDisplay : "none"
		});
	}

	touchDidStart(pEvent) {
		let touch = pEvent.touches[0];
		this.touchEventCoordinates.startX = touch.screenX;
		this.touchEventCoordinates.startY = touch.screenY;
	}

	touchDidMove(pEvent) {
		let touch = pEvent.touches[0];
		this.touchEventCoordinates.endX = touch.screenX;
		this.touchEventCoordinates.endY = touch.screenY;
	}

	touchDidEnd() {
		let MIN_X = 30;
		let MAX_Y = 50;

		var aDirection;
		let aHorizontalDelta = this.touchEventCoordinates.startX - this.touchEventCoordinates.endX;
		let aVerticalDelta = this.touchEventCoordinates.startY - this.touchEventCoordinates.endY;
		// Check to see if the delta of X is great enough to trigger a swipe gesture
		// also see if the Y delta wasnt too drastic to be considered horizontal
		if (Math.abs(aHorizontalDelta) > MIN_X && Math.abs(aVerticalDelta) < MAX_Y) {
			// If delta is negative, its a left swipe, otherwise right
			aDirection = aHorizontalDelta < 0 ? "LEFT" : "RIGHT";
		}
		
		if (aDirection) {
			this.didReceiveSwipeGesture(aDirection);
		}
	}

	didReceiveSwipeGesture(pDirection) {
		if (pDirection === "LEFT") {
			this.didSelectLeftArrow();
		} else if (pDirection === "RIGHT") {
			this.didSelectRightArrow();
		}
	}

	didSelectLeftArrow() {
		this.activateSlideAtIndex(this.state.currentIndex - 1)
	}

	didSelectRightArrow() {
		this.activateSlideAtIndex(this.state.currentIndex + 1)
	}

	didSelectPageIndicatorBulletAtIndex(pIndex) {
		this.activateSlideAtIndex(pIndex)
	}

 }


class ATCarouselSlide extends Component {
	
	render() {
		return (
			<div className="at-carousel-slide" style={{transform: "translateX(" + this.props.transformPercent + "%)"}}>
				{ this.props.children }
			</div>
		)
	}

 }

 export {ATCarousel, ATCarouselSlide}