
class UtilityManager {

	static addMetaTagWithProperty(pProperty, pContent) {
		var aMeta = document.createElement("meta");
		aMeta.setAttribute("property", pProperty);
		aMeta.content = pContent;
		document.getElementsByTagName("head")[0].appendChild(aMeta);
	}


	static copyToClipboard(pText) {
		const anElement = document.createElement("textarea");
		anElement.value = pText;
		document.body.appendChild(anElement);
		anElement.select();
		document.execCommand("copy");
		document.body.removeChild(anElement);
	}

}


export {UtilityManager}
