import React, { Component } from 'react';

import {URL} from './configuration-manager';
// import {DataManager} from './data-manager';
import {PopupManager} from './popup-manager';
import {ATCarousel, ATCarouselSlide} from './at-carousel';


class HomePageComponent extends Component {
	
	componentWillMount() {
		this.homeSlideImageSqlight = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-home.png";
		this.homeSlideImageSolitaire = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-home.png";

		this.showcaseProductIconSqlight = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-icon-48x48.png";
		this.showcaseProductIconSolitaire = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-icon-48x48.png";
	}

	didSelectSqlightTableRow() {
		window.location = URL.sqlightDetails();
	}

	didSelectSolitaireTableRow() {
		window.location = URL.solitaireDetails();
	}

	didSelectContactUsButton() {
		window.location = URL.supportDetails();
	}

	didSelectSubscribeNewsletterButton() {
		PopupManager.sharedInstance.displayErrorMessage("Newsletter Subscription is on hold. Please follow us on twitter to get updates.");

		/*
		var anEmailAddress = document.getElementById("subscribeNewsletter_emailAddress_elementID").value;
		DataManager.sharedInstance.subscribeNewsletter(function(pResult) {
			if (pResult.error !== null) {
				PopupManager.sharedInstance.displayErrorMessage(pResult.error.localizedDescription);
			} else if (pResult.result === true) {
				PopupManager.sharedInstance.displaySuccessMessage("Your request has been submitted successfully.");
			} else {
				PopupManager.sharedInstance.displayErrorMessage("Unknown error.");
			}
		}, anEmailAddress);
		*/
	}

	render() {
		return (
			<div className="content-box">

			<div style={{"height":"20px"}}>&nbsp;</div>

			<ATCarousel className="home-slide-container">
				
				<ATCarouselSlide className="home-slide-row home-slide-solitaire">
					<div className="home-slide-column">
						<div className="home-slide-heading">Solitaire</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="home-slide-subheading">
							Popular Fifty Two Card Deck Game
						</div>
						<div style={{"height":"20px"}}>&nbsp;</div>
						<div className="home-slide-description">
						Solitaire is an iPhone version of popular card game, Solitaire.
						It is played according to the old Klondike Solitaire rules with a 
						standard fifty two card deck without Jokers. Goal is to move all the 
						fifty two cards to four foundation trays, one for each suit as club, 
						diamond, heart and spade. Each of the four foundations is built up with 
						the same suit from Ace to King and the containers can be built down by 
						alternate colors.
						</div>
						<div className="home-slide-description-bottom-padding" style={{"height":"20px"}}>&nbsp;</div>
					</div>
					<div className="home-slide-column">
						<div className="home-slide-image" style={{"backgroundImage": "url(" + this.homeSlideImageSolitaire + ")"}}>&nbsp;</div>
					</div>
				</ATCarouselSlide>
				
				
				<ATCarouselSlide className="home-slide-row home-slide-sqlight">
					<div className="home-slide-column">
						<div className="home-slide-heading">SQLight</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="home-slide-subheading">
							An SQLite Database Manager Tool
						</div>
						<div style={{"height":"20px"}}>&nbsp;</div>
						<div className="home-slide-description">
							SQLight is an SQLite database manager tool. With this tool, you can browse, edit,
							delete data from local SQLite file with easy to use UI and controls. If you want to perform
							SQL query operation on database, there is a query box. Type your query and click the button
							to execute and get results. You can also export data as a file and save it on your drive.
						</div>
						<div className="home-slide-description-bottom-padding" style={{"height":"20px"}}>&nbsp;</div>
					</div>
					<div className="home-slide-column">
						<div className="home-slide-image" style={{"backgroundImage": "url(" + this.homeSlideImageSqlight + ")"}}>&nbsp;</div>
					</div>
				</ATCarouselSlide>
				
			</ATCarousel>


			<div style={{"height":"15px"}}>&nbsp;</div>
			<div className="showcase-row"><div className="showcase-column"><div className="horizontal-line">&nbsp;</div></div></div>
			<div style={{"height":"15px"}}>&nbsp;</div>


			
			<div className="showcase-row">
				<div className="showcase-column">
					<div className="section-heading">Our Products</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="showcase-description">
						<div className="showcase-product-row" onClick={this.didSelectSqlightTableRow}>
							<div className="showcase-product-column-icon">
								<div className="showcase-product-icon" style={{"backgroundImage": "url(" + this.showcaseProductIconSqlight + ")"}}>&nbsp;</div>
							</div>
							<div className="showcase-product-column-description">
								<div className="showcase-product-title">SQLight</div>
								<div className="showcase-product-text">An SQLite Database Manager Tool</div>
							</div>
						</div>
						<div style={{"height":"13px"}}>&nbsp;</div>
						<div className="showcase-product-row" onClick={this.didSelectSolitaireTableRow}>
							<div className="showcase-product-column-icon">
								<div className="showcase-product-icon" style={{"backgroundImage": "url(" + this.showcaseProductIconSolitaire + ")"}}>&nbsp;</div>
							</div>
							<div className="showcase-product-column-description">
								<div className="showcase-product-title">Solitaire</div>
								<div className="showcase-product-text">Popular Fifty Two Card Deck Game</div>
							</div>
						</div>
					</div>
					<div className="horizontal-line showcase-column-border-bottom"></div>
				</div>
				
				<div className="showcase-column showcase-column-vertical-line">
					<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
				</div>
				
				<div className="showcase-column">
					<div className="section-heading">Our Services</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="showcase-description">
						<div className="showcase-text">You have got an idea for a software, but don't have technical expertise. Let us help you to make it a reality.</div>
						<div className="showcase-text">You liked our product, but need some customization or more features to suit your needs. We would be happy to help you.</div>
						<div className="showcase-text">Please <span onClick={this.didSelectContactUsButton} className="showcase-link">contact us</span> with your requirements and we will get back to you.</div>
					</div>
					<div className="horizontal-line showcase-column-border-bottom"></div>
				</div>
				
				<div className="showcase-column showcase-column-vertical-line">
					<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
				</div>
				
				<div className="showcase-column">
					<div className="section-heading">Free Newsletter Subscription</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="showcase-description">
						<div className="showcase-text">Please subscribe to our free newsletter and keep yourself up to date on the latest events, offers, app launch, app updates, surprise features etc.</div>
						<div className="showcase-text">Don't worry! We will not flood your mailbox with advertising emails.</div>
						<div className="app-input-group showcase-subscription-input-group">
							<input type="text" id="subscribeNewsletter_emailAddress_elementID" className="app-input-textfield showcase-subscription-textfield" placeholder="" required />
							<label htmlFor="subscribeNewsletter_emailAddress_elementID">Email Address</label>
							<input type="submit" value="Submit" className="app-input-button showcase-subscription-button" onClick={this.didSelectSubscribeNewsletterButton} />
						</div>
					</div>
				</div>
			</div>
			
			<div style={{"height":"20px"}}>&nbsp;</div>

			</div>
		);
	}
}

export default HomePageComponent;
