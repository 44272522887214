import React, { Component } from 'react';

import ProfileBarComponent from './profile-bar-component';
import NavigationBarComponent from './navigation-bar-component';
import RouterComponent from './router-component';
import FooterBarComponent from './footer-bar-component';


class AppComponent extends Component {
	render() {
		return (
			<div>
				<ProfileBarComponent />
				<NavigationBarComponent />
				<RouterComponent />
				<FooterBarComponent />
			</div>
		);
	}
}

export default AppComponent;
