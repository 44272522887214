import React, { Component } from 'react';

import {URL} from './configuration-manager';


export class ProfileBarComponent extends Component {
	
	didSelectFacebookButton() {
		window.location = URL.aurvanFacebookPage();
	}

	didSelectTwitterButton() {
		window.location = URL.aurvanTwitterPage();
	}

	didSelectUserButton() {
		window.location = URL.loginCustomerForm();
	}

	render() {
		return (
			<div className="profile-bar">
				<div className="content-box">
					<div className="social-bar">
						<span className="app-input-button app-input-button-square square-button-facebook" onClick={this.didSelectFacebookButton}></span>
						&nbsp;<span className="app-input-button app-input-button-square square-button-twitter" onClick={this.didSelectTwitterButton}></span>
					</div>
					<div className="user-info-bar">
						<span className="app-input-button app-input-button-square square-button-user-info" onClick={this.didSelectUserButton}></span>
					</div>
				</div>
			</div>
		)
	}

 }

 export default ProfileBarComponent;