import React, { Component } from 'react';

import {URL} from './configuration-manager';
import { ATQuickLook } from '../atkit-react/at-quick-look';
import {UtilityManager} from './utility-manager';


class SqlightDetailsComponent extends Component {

	constructor(props) {
		super(props);
		
		this.quickLook = React.createRef();
	}

	didSelectQuickLook(pId) {
		var aUrl = null;
		
		if (pId === "sqlightScreenshotHome") {
			aUrl = this.sqlightScreenshotHome;
		} else if (pId === "sqlightScreenshotTableStructure") {
			aUrl = this.sqlightScreenshotTableStructure;
		} else if (pId === "sqlightScreenshotTableStructureEdit") {
			aUrl = this.sqlightScreenshotTableStructureEdit;
		} else if (pId === "sqlightScreenshotTableData") {
			aUrl = this.sqlightScreenshotTableData;
		} else if (pId === "sqlightScreenshotTableDataAddRow") {
			aUrl = this.sqlightScreenshotTableDataAddRow;
		} else if (pId === "sqlightScreenshotTableDataEditRow") {
			aUrl = this.sqlightScreenshotTableDataEditRow;
		} else if (pId === "sqlightScreenshotSqlQuery") {
			aUrl = this.sqlightScreenshotSqlQuery;
		} else if (pId === "sqlightScreenshotTabsDropdown") {
			aUrl = this.sqlightScreenshotTabsDropdown;
		} else if (pId === "sqlightScreenshotTabsScroll") {
			aUrl = this.sqlightScreenshotTabsScroll;
		} else if (pId === "sqlightScreenshotRegularUpdates") {
			aUrl = this.sqlightScreenshotRegularUpdates;
		} else if (pId === "sqlightScreenshotSaveBlob") {
			aUrl = this.sqlightScreenshotSaveBlob;
		} else if (pId === "sqlightScreenshotSaveText") {
			aUrl = this.sqlightScreenshotSaveText;
		} else if (pId === "sqlightScreenshotQuickLook") {
			aUrl = this.sqlightScreenshotQuickLook;
		} else if (pId === "sqlightScreenshotOutlineMenu") {
			aUrl = this.sqlightScreenshotOutlineMenu;
		}
		if (aUrl != null) {
			this.quickLook.current.showWithUrl(aUrl);
		}
	}

	didSelectDownloadSqlightLatestButton() {
		window.location = URL.downloadSqlightLatest();
	}

	didSelectSqlightDocumentationButton() {
		window.location = URL.sqlightDocumentation();
	}

	didSelectShareSqlightButton() {
		var aUrlString = URL.shareSqlight();
		UtilityManager.copyToClipboard(aUrlString);

		var aShareSqlightButton = document.getElementById("shareSqlightButton");
		aShareSqlightButton.innerText = "Link copied";
		setTimeout(function(){
			aShareSqlightButton.innerText = "Share";
		}, 1000);
	}

	componentWillMount() {
		this.sqlightScreenshotHomeThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-home-thumbnail.png";
		this.sqlightScreenshotHome = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-home.png";
		
		this.sqlightScreenshotTableStructureThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-structure-thumbnail.png";
		this.sqlightScreenshotTableStructure = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-structure.png";

		this.sqlightScreenshotTableStructureEditThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-structure-edit-thumbnail.png";
		this.sqlightScreenshotTableStructureEdit = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-structure-edit.png";

		this.sqlightScreenshotTableDataThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-data-thumbnail.png";
		this.sqlightScreenshotTableData = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-data.png";

		this.sqlightScreenshotTableDataAddRowThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-data-add-row-thumbnail.png";
		this.sqlightScreenshotTableDataAddRow = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-data-add-row.png";

		this.sqlightScreenshotTableDataEditRowThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-data-edit-row-thumbnail.png";
		this.sqlightScreenshotTableDataEditRow = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-table-data-edit-row.png";

		this.sqlightScreenshotSqlQueryThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-sql-query-thumbnail.png";
		this.sqlightScreenshotSqlQuery = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-sql-query.png";

		this.sqlightScreenshotTabsDropdownThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-tabs-dropdown-thumbnail.png";
		this.sqlightScreenshotTabsDropdown = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-tabs-dropdown.png";

		this.sqlightScreenshotTabsScrollThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-tabs-scroll-thumbnail.png";
		this.sqlightScreenshotTabsScroll = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-tabs-scroll.png";

		this.sqlightScreenshotRegularUpdatesThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-regular-updates-thumbnail.png";
		this.sqlightScreenshotRegularUpdates = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-regular-updates.png";

		this.sqlightScreenshotSaveBlobThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-save-blob-thumbnail.png";
		this.sqlightScreenshotSaveBlob = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-save-blob.png";

		this.sqlightScreenshotSaveTextThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-save-text-thumbnail.png";
		this.sqlightScreenshotSaveText = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-save-text.png";

		this.sqlightScreenshotQuickLookThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-quick-look-thumbnail.png";
		this.sqlightScreenshotQuickLook = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-quick-look.png";

		this.sqlightScreenshotOutlineMenuThumbnail = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-outline-menu-thumbnail.png";
		this.sqlightScreenshotOutlineMenu = process.env.PUBLIC_URL + "/htcontents/sqlight/images/sqlight-screenshot-outline-menu.png";
	}

	render() {
		return (

			<div className="content-box">
	
			<div className="page-heading">SQLight</div>

			<div className="product-details-description">
				SQLight is an SQLite database manager tool. With this tool, you can browse, edit, delete
				data from local SQLite file with easy to use UI and controls. If you want to perform SQL
				query operation on database, there is a query box. Type your query and click the button
				to execute and get results. You can also export data as a file and save it on your drive.
			</div>
			
			
			<div style={{"height":"15px"}}>&nbsp;</div>
			
			
			<div className="product-details-row"><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row">
				<div className="product-details-column">
					<div className="section-heading">System Requirements</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Mac computer with an Intel Core processor</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>OS X version 10.7 (Lion) or later</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>1.6 MB or more of available disk space</div>
					</div>
				</div>
				
				<div className="product-details-column-vertical-line">
					<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
				</div>
				
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="app-input-button-quick-link product-details-quick-link" onClick={this.didSelectDownloadSqlightLatestButton}>Download Latest Version - 3.0.0.0</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="app-input-button-quick-link product-details-quick-link" onClick={this.didSelectSqlightDocumentationButton}>Documentation</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="app-input-button-quick-link product-details-quick-link" id="shareSqlightButton" onClick={this.didSelectShareSqlightButton}>Share</div>
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row" style={{"display":"none"}}><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row" style={{"display":"none"}}>
				<div className="product-details-column">
					<div className="section-heading">Awards and Recognition</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-awards-image-container">
						<a href="http://www.download-zone.co/mac/development/other/sqlight/" className="product-award-link product-award-link-sqlight-download-zone-co">&nbsp;</a>
						<a href="http://www.download-zone.co/mac/development/other/sqlight/" className="product-award-link product-award-link-sqlight-download-zone-co">&nbsp;</a>
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row"><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row">
				<div className="product-details-column">
					<div className="section-heading">Future Plans</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div style={{"textAlign":"justify"}}>
						<div>Next major release will be version 4.0.0.0. Following are the updates planned for the same.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Bug fixing will always continue for every release and we will make sure that the application will run without errors, efficiently and smoothly.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>SQL query history will be supported in this version.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Preferences to set custom colors for sql query syntax highlighting will be supported in this version.</div>
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row">
				<div className="product-details-column">
					<div style={{"textAlign":"justify"}}>
						Please follow us <a target="_blank" rel="noopener noreferrer" className="button-twitter" title="Follow @AurvanCom on Twitter"
						href="https://twitter.com/AurvanCom"><i></i><span className="label">Follow</span></a> to know exact
						date of the release and other new features those will be added.
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row"><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">Free</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						SQLight is totally free. No trial version with limited timespan to use. No demo version with many of the features disabled. You will get a fully functional application.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotTabsScrollThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotTabsScroll")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">Regular Updates</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						We already have planned and started working on features for next releases. Also there will be bug fixing as reported by our QA people and app users.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotRegularUpdatesThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotRegularUpdates")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">Beautiful looks and carefully designed, clean and neat layout</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						<div>While designing SQLight we took due care to make it easy to understand and use. We kept it simple, neat and of course beautiful.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div>You can see list of tables, views, indexes on left panel and right panel provides you controls to manage them.</div>
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotHomeThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotHome")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">Use tabs to open multiple databases at the same time</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						SQLight provides tab feature (similar to what we have in web browsers). You can open another database in a new tab and can work on different databases at the same time.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotTabsDropdownThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotTabsDropdown")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">Save any data including BLOB from SQLite database</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						SQLight provides a feature that allows you to save any data e.g. CHAR, VARCHAR, TEXT, INT, BLOB etc. from SQLite database. Just right click on any of the cell to save contents as file.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotSaveBlobThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotSaveBlob")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">Quick look table data</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						SQLight provides a feature that allows you to take a quick look at table data like image, plain text, html etc. Just right click on any of the cell and select Quick Look option.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotQuickLookThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotQuickLook")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">SQL query with syntax coloring</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						You can perform SQL query operation on database. There is a query box with syntax coloring support, to help you with use of SQL keywords and function names.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotSqlQueryThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotSqlQuery")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">Features to make your task easy</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						<div>SQLight provides you various keyboard shortcuts, right click menus to ease your task.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>You can navigate between tabs with (Command + 1), (Command + 2) etc.</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>You can navigate between segments with (Ctrl + 1) for Table Structure, (Ctrl + 2) for Table Data and (Ctrl + 3) for SQL Query.</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>You can rename a table just by selecting right click menu.</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Simply double click on data row to edit it.</div>
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="sqlight-screenshot" style={{"backgroundImage": "url(" + this.sqlightScreenshotOutlineMenuThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("sqlightScreenshotOutlineMenu")}>
						</div>
					</div>
				</div>
			</div>
			
			
			<div style={{"height":"20px"}}>&nbsp;</div>

			<ATQuickLook ref={this.quickLook} />
			
			</div>

		);
	}
}

export default SqlightDetailsComponent;
