
class PopupManager {
	static sharedInstance = new PopupManager();

	displayErrorMessage(pMessage) {
		alert(pMessage);
	}

	displaySuccessMessage(pMessage) {
		alert(pMessage);
	}

}


export {PopupManager}
