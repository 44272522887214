import React, { Component } from 'react';


class SearchLinkAffiliateComponent extends Component {
	
	render() {
		return (
			<div className="content-box">

				<div className="page-heading">Link Affiliates</div>
				
				<div style={{"textIndent":"25px"}}>
					<b>&#x25CF; </b>
					<a href="http://www.bestsoftware4download.com/" target="_blank" rel="noopener noreferrer"
					title="Shareware and freeware downloads">Best Software 4 Download</a>
				</div>
				
				
				<div style={{"height":"20px"}}>&nbsp;</div>
				
			</div>
		);
	}
}

export default SearchLinkAffiliateComponent;
