import React, { Component } from 'react';


class PortfolioDetailsComponent extends Component {
	render() {
		return (
			<div className="content-box">
				<div className="page-heading">Portfolio</div>
	
				<div className="portfolio-details-row">
					<div className="portfolio-details-column portfolio-details-column-about-us">
						<div className="section-heading">About Us</div>
						<div className="portfolio-details-description">
							Aurvan Technologies provides a complete solution for utility software and internet based
							applications including software product development, maintenance and support.
						</div>
						<div className="horizontal-line portfolio-details-border-bottom portfolio-details-border-bottom-about-us"></div>
					</div>
				</div>
				
				<div className="portfolio-details-row">
					<div className="portfolio-details-column">
						<div className="section-heading">Values</div>
						<div className="portfolio-details-description">
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Integrity: </b>Be honest and open to inspire the trust.</div>
							<div style={{"height":"7px"}}>&nbsp;</div>
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Quality: </b>If you are going to do something, then do it right.</div>
							<div style={{"height":"7px"}}>&nbsp;</div>
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Optimization: </b>There are pros and cons to everything, choose whatever is best suited.</div>
							<div style={{"height":"7px"}}>&nbsp;</div>
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Focus: </b>Put your strength in well defined field and give your best.</div>
						</div>
						<div className="horizontal-line portfolio-details-border-bottom"></div>
					</div>
					
					<div className="portfolio-details-column-vertical-line">
						<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
					</div>
					
					<div className="portfolio-details-column">
						<div className="section-heading">Strengths</div>
						<div className="portfolio-details-description">
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Technical Knowledge: </b>Developers with thorough technical knowledge in their domain, and good understanding of supporting domains.</div>
							<div style={{"height":"5px"}}>&nbsp;</div>
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Communication: </b>Well formulated emails, documentation that is easy to understand.</div>
							<div style={{"height":"5px"}}>&nbsp;</div>
							<div style={{"textIndent":"25px"}}><b>&#x25CF; Analytical Skills: </b>Experienced team members to identify and critique problems, recognise underlying principles, define parameters, and construct strategies and solutions.</div>
						</div>
					</div>
				</div>
				
				<div style={{"height":"20px"}}>&nbsp;</div>
			</div>
		);
	}
}

export default PortfolioDetailsComponent;
