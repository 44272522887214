/** @pragma_mark - App Constants **/

class AppEnvironment {
	static dev = "dev";
	static beta = "beta";
	static prod = "prod";
}


class AppConstants {
	static appEnvironment = AppEnvironment.prod;

	static headerPlusFooterHeight = (60 + 90 + 60);


	static appHtcontentsAbsoluteUrl() {
		var aReturnVal = process.env.PUBLIC_URL + "/htcontents/";
		if (AppConstants.appEnvironment === AppEnvironment.dev) {
			aReturnVal = "http://www.aurvan.net/com-aurvan-satva-ui-reactjs-htcontents/";
		} else {
			aReturnVal = "http://www.aurvan.com/com-aurvan-satva-ui-reactjs-htcontents/";
		}
		return aReturnVal;
	}

	static appDossierUrl() {
		var aReturnVal = "http://www.aurvan.com/com-aurvan-satva-ui-reactjs-dossier/";
		if (AppConstants.appEnvironment === AppEnvironment.dev) {
			aReturnVal = "http://www.aurvan.net/com-aurvan-satva-ui-reactjs-dossier/";
		}
		return aReturnVal;
	}

	static appApiUrl() {
		var aReturnVal = "http://www.aurvan.com/com-aurvan-satva-api-php/index.php";
		if (AppConstants.appEnvironment === AppEnvironment.dev) {
			aReturnVal = "http://www.aurvan.net/com-aurvan-satva-api-php/index.php";
		}
		return aReturnVal;
	}
}


/** @pragma_mark - URLs **/

class URL {

	static aurvanFacebookPage() {
		return "https://www.facebook.com/AurvanTech";
	}

	static aurvanTwitterPage() {
		return "https://twitter.com/AurvanTech";
	}

	static homePage() {
		return "/";
	}

	static searchProduct() {
		return "/products/";
	}

	static supportDetails() {
		return "/support/";
	}

	static portfolioDetails() {
		return "/portfolio/";
	}

	static privacyPolicyDetails() {
		return "/privacy-policy/";
	}

	static loginCustomerForm() {
		return "/login/";
	}

	static searchLinkAffiliate() {
		return "/link-affiliates/";
	}

	static sqlightDetails() {
		return "/sqlight/";
	}

	static sqlightDocumentation() {
		return AppConstants.appHtcontentsAbsoluteUrl() + "sqlight/helpbook/index.html";
	}

	static shareSqlight() {
		return AppConstants.appHtcontentsAbsoluteUrl() + "solitaire/sqlight-og.html";
	}

	static downloadSqlightLatest() {
		return AppConstants.appDossierUrl() + "sqlight-v3_0_0_0-app.dmg";
	}


	static solitaireDetails() {
		return "/solitaire/";
	}

	static shareSolitaire() {
		return AppConstants.appHtcontentsAbsoluteUrl() + "solitaire/solitaire-og.html";
	}

	static downloadSolitaireIosLatest() {
		return "https://apps.apple.com/in/app/aurvan-solitaire/id1519751060";
	}

	static downloadSolitaireAndroidLatest() {
		return "https://play.google.com/store/apps/details?id=com.aurvan.solitaire.ui.android";
	}

}


export {AppConstants, URL, AppEnvironment}
