import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {URL, AppConstants} from './configuration-manager';
import HomePageComponent from './home-page-component';
import SearchProductComponent from './search-product-component';
import SupportDetailsComponent from './support-details-component';
import PortfolioDetailsComponent from './portfolio-details-component';
import PrivacyPolicyDetailsComponent from './privacy-policy-details-component';
import LoginCustomerComponent from './login-customer-component';
import SqlightDetailsComponent from './sqlight-details-component';
import SolitaireDetailsComponent from './solitaire-details-component';
import SearchLinkAffiliateComponent from './search-link-affiliate-component';


class RouterComponent extends Component {
	constructor() {
		super();

		this.updateAppViewHeight()
		window.addEventListener("resize", this.windowDidResize.bind(this));
	}

	windowDidResize() {
		this.updateAppViewHeight()
		this.forceUpdate();
	}

	updateAppViewHeight() {
		this.routerHeight = window.innerHeight - AppConstants.headerPlusFooterHeight;
	}
	
	render() {
		return (
			<div style={{minHeight: this.routerHeight}}>
			<Router>
				<Switch>
					<Route exact path={URL.homePage()} component={HomePageComponent} />
					<Route exact path={URL.searchProduct()} component={SearchProductComponent} />
					<Route exact path={URL.supportDetails()} component={SupportDetailsComponent} />
					<Route exact path={URL.portfolioDetails()} component={PortfolioDetailsComponent} />
					<Route exact path={URL.privacyPolicyDetails()} component={PrivacyPolicyDetailsComponent} />
					<Route exact path={URL.loginCustomerForm()} component={LoginCustomerComponent} />
					<Route exact path={URL.sqlightDetails()} component={SqlightDetailsComponent} />
					<Route exact path={URL.solitaireDetails()} component={SolitaireDetailsComponent} />
					<Route exact path={URL.searchLinkAffiliate()} component={SearchLinkAffiliateComponent} />
				</Switch>
			</Router>
			</div>
		);
	}
}

export default RouterComponent;
