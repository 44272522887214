import React, { Component } from 'react';

import {URL} from './configuration-manager';
import { ATQuickLook } from '../atkit-react/at-quick-look';
import {UtilityManager} from './utility-manager';


class SolitaireDetailsComponent extends Component {

	constructor(props) {
		super(props);
		
		this.quickLook = React.createRef();
	}

	didSelectQuickLook(pId) {
		var aUrl = null;
		
		if (pId === "solitaireScreenshotHome") {
			aUrl = this.solitaireScreenshotHome;
		} else if (pId === "solitaireScreenshotUndo") {
			aUrl = this.solitaireScreenshotUndo;
		} else if (pId === "solitaireScreenshotTheme") {
			aUrl = this.solitaireScreenshotTheme;
		} else if (pId === "solitaireScreenshotAssistance") {
			aUrl = this.solitaireScreenshotAssistance;
		} else if (pId === "solitaireScreenshotAutoComplete") {
			aUrl = this.solitaireScreenshotAutoComplete;
		} else if (pId === "solitaireScreenshotViewSolutions") {
			aUrl = this.solitaireScreenshotViewSolutions;
		}
		if (aUrl != null) {
			this.quickLook.current.showWithUrl(aUrl);
		}
	}

	didSelectDownloadSolitaireIosLatestButton() {
		window.location = URL.downloadSolitaireIosLatest();
	}

	didSelectDownloadSolitaireAndroidLatestButton() {
		window.location = URL.downloadSolitaireAndroidLatest();
	}

	didSelectShareSolitaireButton() {
		var aUrlString = URL.shareSolitaire();
		UtilityManager.copyToClipboard(aUrlString);

		var aShareSolitaire = document.getElementById("shareSolitaireButton");
		aShareSolitaire.innerText = "Link copied";
		setTimeout(function(){
			aShareSolitaire.innerText = "Share";
		}, 1000);
	}

	didSelectSupportButton() {
		window.location = URL.supportDetails();
	}

	componentWillMount() {
		this.solitaireScreenshotHomeThumbnail = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-home-thumbnail.png";
		this.solitaireScreenshotHome = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-home.png";
		
		this.solitaireScreenshotUndoThumbnail = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-undo-thumbnail.png";
		this.solitaireScreenshotUndo = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-undo.png";

		this.solitaireScreenshotThemeThumbnail = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-theme-thumbnail.png";
		this.solitaireScreenshotTheme = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-theme.png";

		this.solitaireScreenshotAssistanceThumbnail = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-assistance-thumbnail.png";
		this.solitaireScreenshotAssistance = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-assistance.png"

		this.solitaireScreenshotAutoCompleteThumbnail = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-auto-complete-thumbnail.png";
		this.solitaireScreenshotAutoComplete = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-auto-complete.png"

		this.solitaireScreenshotViewSolutionsThumbnail = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-view-solutions-thumbnail.png";
		this.solitaireScreenshotViewSolutions = process.env.PUBLIC_URL + "/htcontents/solitaire/images/solitaire-screenshot-view-solutions.png"
	}

	render() {
		return (

			<div className="content-box">
	
			<div className="page-heading">Solitaire</div>

			<div className="product-details-description">
				Solitaire is an iPhone version of popular card game, Solitaire. It is played according to the old Klondike Solitaire rules with a standard fifty two card deck without Jokers. Goal is to move all the fifty two cards to four foundation trays, one for each suit as club, diamond, heart and spade. Each of the four foundations is built up with the same suit from Ace to King and the containers can be built down by alternate colors.
			</div>
			
			
			<div style={{"height":"15px"}}>&nbsp;</div>
			
			
			<div className="product-details-row"><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row">
				<div className="product-details-column">
					<div className="section-heading">System Requirements</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						<div><b>&#x25CF; </b>iOS App</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>iPhone or iPad Device</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>iOS version 11 or later</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>10 MB or more of available disk space</div>

						<div style={{"height":"8px"}}>&nbsp;</div>
						<div><b>&#x25CF; </b>Android App</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Android Device</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Android version 6 or later</div>
						<div style={{"height":"8px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>8 MB or more of available disk space</div>
					</div>
				</div>
				
				<div className="product-details-column-vertical-line">
					<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
				</div>
				
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="app-input-button-quick-link product-details-quick-link" onClick={this.didSelectDownloadSolitaireIosLatestButton}>Download From App Store (iOS)</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="app-input-button-quick-link product-details-quick-link" onClick={this.didSelectDownloadSolitaireAndroidLatestButton}>Download From Play Store (Android)</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="app-input-button-quick-link product-details-quick-link" id="shareSolitaireButton" onClick={this.didSelectShareSolitaireButton}>Share</div>
						<div style={{"height":"10px"}}>&nbsp;</div>
						<div className="app-input-button-quick-link product-details-quick-link" onClick={this.didSelectSupportButton}>Support</div>
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row" style={{"display":"none"}}><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row" style={{"display":"none"}}>
				<div className="product-details-column">
					<div className="section-heading">Awards and Recognition</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-awards-image-container">
						<a href="http://www.download-zone.co/mac/development/other/solitaire/" className="product-award-link product-award-link-solitaire-download-zone-co">&nbsp;</a>
						<a href="http://www.download-zone.co/mac/development/other/solitaire/" className="product-award-link product-award-link-solitaire-download-zone-co">&nbsp;</a>
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row"><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row">
				<div className="product-details-column">
					<div className="section-heading">Future Plans</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div style={{"textAlign":"justify"}}>
						<div>Next major release will be version 1.1.0.0. Following are the updates planned for the same.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Bug fixing will always continue for every release and we will make sure that the application will run without errors, efficiently and smoothly.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div style={{"textIndent":"25px"}}><b>&#x25CF; </b>Paper theme will be added.</div>
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row">
				<div className="product-details-column">
					<div style={{"textAlign":"justify"}}>
						Please <a target="_blank" rel="noopener noreferrer" className="button-twitter" title="Follow @AurvanCom on Twitter"
						href="https://twitter.com/AurvanCom"><i></i><span className="label">Follow</span></a> us to know exact
						date of the release and other new features those will be added.
					</div>
				</div>
			</div>
			
			
			<div className="product-details-row"><div className="product-details-column"><div className="horizontal-line">&nbsp;</div></div></div>
			
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">Free</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
					Solitaire is free to play and to enjoy all the major features. You can purchase premium features if you want additional accessory features that will ease your tasks.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="solitaire-screenshot" style={{"backgroundImage": "url(" + this.solitaireScreenshotHomeThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("solitaireScreenshotHome")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">Themes</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						<div>While designing Solitaire we took due care to make it easy to understand and use. We kept it simple, neat and of course beautiful.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div>Moreover you can set any available theme (Wood, Jungle, Metal) to have a look and feel as you like.</div>
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="solitaire-screenshot" style={{"backgroundImage": "url(" + this.solitaireScreenshotThemeThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("solitaireScreenshotTheme")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">Undo / Redo</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						You can undo your moves to try various routes to solve the game. You can also redo the undone move if you want.
						Simply open the menu from the button at bottom-right corner and tap on Undo or Redo menu button.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="solitaire-screenshot" style={{"backgroundImage": "url(" + this.solitaireScreenshotUndoThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("solitaireScreenshotUndo")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">Intelligent Assistance</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
						<div>Solitaire provides you general indication of compatibility of cards for drop position, while you are moving the cards after lifting. The tray on the desk will glow green to indicate compatibility of cards.</div>
						<div style={{"height":"5px"}}>&nbsp;</div>
						<div>You can also tap on any of the cards to move them, without even lifting them, to the first obvious position.</div>
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="solitaire-screenshot" style={{"backgroundImage": "url(" + this.solitaireScreenshotAssistanceThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("solitaireScreenshotAssistance")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-dark">
				<div className="product-details-column">
					<div className="section-heading">Auto Complete</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
					Solitaire provides a feature that allows you to complete the game automatically once you have revealed all the cards from the container trays.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="solitaire-screenshot" style={{"backgroundImage": "url(" + this.solitaireScreenshotAutoCompleteThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("solitaireScreenshotAutoComplete")}>
						</div>
					</div>
				</div>
			</div>
			
			<div className="product-details-row product-details-row-light">
				<div className="product-details-column">
					<div className="section-heading">View Solutions</div>
					<div style={{"height":"8px"}}>&nbsp;</div>
					<div className="product-details-description">
					Solitaire provides a feature that allows you to view, observe and study the moves of the games solved by different players. You can also submit your own solutions for the games, provided you solve them in less number of moves than already available solution.
					</div>
				</div>
				<div className="product-details-column">
					<div className="product-details-description">
						<div className="solitaire-screenshot" style={{"backgroundImage": "url(" + this.solitaireScreenshotViewSolutionsThumbnail + ")"}}
						onClick={()=>this.didSelectQuickLook("solitaireScreenshotViewSolutions")}>
						</div>
					</div>
				</div>
			</div>
			
			
			<div style={{"height":"20px"}}>&nbsp;</div>

			<ATQuickLook ref={this.quickLook} />
			
			</div>

		);
	}
}

export default SolitaireDetailsComponent;
