import React, { Component } from 'react';


class SupportDetailsComponent extends Component {
	render() {
		return (
			<div className="content-box">
			
				<div className="page-heading">Support</div>
				
				<div className="support-details-row">
					<div className="support-details-column">
						<div className="section-heading">Customer Service</div>
						<div className="support-details-description">
							If you are already a customer of Aurvan Technologies and have questions regarding
							your account, billing, shipping etc., please contact us on
							&nbsp;<span className="email-aurvan email-aurvan-support">&nbsp;</span>.
						</div>
						<div className="horizontal-line support-details-border-bottom"></div>
					</div>
					
					<div className="support-details-column-vertical-line">
						<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
					</div>
					
					<div className="support-details-column">
						<div className="section-heading">Sales</div>
						<div className="support-details-description">
							If you are planning to avail yourself of Aurvan Technologies products and / or services
							and have queries about the same, please contact us on
							&nbsp;<span className="email-aurvan email-aurvan-sales">&nbsp;</span>.
						</div>
						<div className="horizontal-line support-details-border-bottom"></div>
					</div>
				</div>
				
				<div style={{"clear":"both", "width":"100%"}} className="support-details-horizontal-line-container">
					<div className="support-details-column">
						<div className="horizontal-line support-details-separator-horizontal">&nbsp;</div>
					</div>
					
					<div className="support-details-column-vertical-line">
						&nbsp;
					</div>
					
					<div className="support-details-column">
						<div className="horizontal-line support-details-separator-horizontal">&nbsp;</div>
					</div>
				</div>
				
				<div className="support-details-row">
					<div className="support-details-column">
						<div className="section-heading">Feedback</div>
						<div className="support-details-description">
							For any comment, feedback or suggestion, please contact us on
							&nbsp;<span className="email-aurvan email-aurvan-feedback">&nbsp;</span>.
							We always appreciate your feedback.
							<div style={{"height":"2px"}}>&nbsp;</div>
							If you want to report bug / issue in our product / service, please use
							bug / issue tracker for respective product / service.
						</div>
						<div className="horizontal-line support-details-border-bottom"></div>
					</div>
					
					<div className="support-details-column-vertical-line">
						<div className="vertical-line" style={{"display":"inline-block"}}>&nbsp;</div>
					</div>
					
					<div className="support-details-column">
						<div className="section-heading">Miscellaneous</div>
						<div className="support-details-description">
							If you are not sure which department you should contact to get your query
							answered, just drop an email to <span className="email-aurvan email-aurvan-info">&nbsp;</span>.
							We will redirect it to appropriate department.
						</div>
					</div>
				</div>
				
				<div style={{"height":"20px"}}>&nbsp;</div>
			
			</div>
		);
	}
}

export default SupportDetailsComponent;
